<template>
  <div class="date-and-time">
    <div class="date-and-time-left">
      <m-page-title :title="titleStr" address />
      <el-form
        :model="dateForm"
        ref="dateForm"
        label-position="top"
        size="medium"
        class="width-small"
        @submit.native.prevent
      >
        <el-form-item label="Select date">
          <el-date-picker
            v-model="dateForm.date"
            class="date-box"
            type="date"
            placeholder="DD/MM/YYYY"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1"
            @change="datePicker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Select time">
          <el-popover
            :visible-arrow="false"
            v-model="selectTimeVisible"
            placement="bottom"
            trigger="click"
          >
            <div class="select-time-box">
              <!--              <div class="select-time-top">-->
              <!--                <div @click="resetTime" class="reset-btn">Reset</div>-->
              <!--                <div class="time-data">-->
              <!--                  <img src="@/static/svg/date&time/Date.svg" alt="" />-->
              <!--                  {{ dateForm.time ? dateForm.time : "00:00 - 00:00" }}-->
              <!--                </div>-->
              <!--              </div>-->
              <template v-if="timeData.length">
                <div class="select-time-content g-scroll-style">
                  <div
                    @click="selectTime(idx)"
                    class="select-time-item"
                    :class="[
                      idx === timeIndex ? 'active' : '',
                      time.isCanAppointment ? '' : 'disabled',
                    ]"
                    v-for="(time, idx) in timeData"
                    v-bind:key="idx"
                  >
                    {{ time.startTime | dateVal }} -
                    {{ time.endTime | dateVal }}
                  </div>
                </div>
                <el-button
                  class="claim-time-btn"
                  type="primary"
                  @click="claimTime"
                  >Confirm</el-button
                >
                <el-button class="claim-time-btn cancel" @click="timeSlotCancel"
                  >Cancel</el-button
                >
              </template>
              <template v-else>
                <span class="no_data">no data available</span>
              </template>
            </div>
            <el-input
              placeholder="00:00 - 00:00"
              slot="reference"
              v-model="dateForm.time"
              readonly
            >
              <img
                class="time-icon"
                slot="suffix"
                src="@/static/svg/date&time/Time.svg"
                alt=""
              />
            </el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="Phone">
          <el-input v-model="dateForm.phone" maxlength="20" />
        </el-form-item>
        <el-form-item label="Email">
          <el-input v-model="dateForm.email" maxlength="100" />
        </el-form-item>
        <el-form-item label="Comment">
          <el-input
            v-model="dateForm.comment"
            type="textarea"
            maxlength="1000"
          />
        </el-form-item>
        <div class="line flex-row">
          <m-back class="link" :barStatus="barStatus" />
          <el-button
            v-if="bookdata.type === 2 || total == 0"
            :disabled="nextDisabled"
            class="action-btn"
            type="primary"
            @click="facilityBooking"
            >Make a Booking</el-button
          >
          <el-button
            v-else
            :disabled="nextDisabled"
            class="action-btn"
            type="primary"
            @click="facilityBooking"
            >Proceed to payment</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="date-and-time-right" v-if="bookdata.type !== 2">
      <pay-total
        :option-type="optionType"
        :date="dateForm.date"
        :time="dateForm.time"
        :moneyList="moneyList"
        :total="total"
        :apartment-id="room.apartmentId"
        :apartment-unit-id="room.apartmentUnitId"
      />
    </div>
    <m-dialog
      v-model="dialogShow"
      mask
      unBack
      cancelHide
      confirm-txt="Return to my booking"
      :address="apartment.name"
      :title="successTittle"
      :content="contentTxt"
      @confirm="confirmPopup"
      :image="apartment.photo"
    ></m-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import PayTotal from "@/components/PayTotal";
import { dateVal, regex } from "@/utils/tools";

export default {
  name: "",
  components: { PayTotal },
  data() {
    return {
      advanceHour: 0,
      barStatus: "",
      successTittle: "",
      selectTimeVisible: false,
      timeIndex: "",
      timeData: [],
      dialogShow: false,
      contentTxt: "",
      total: 0,
      optionType: 1, //1.booking ,2.change
      moneyList: [
        {
          label: "Subtotal（GST Exclude）",
          value: "0",
        },
        {
          label: "GST",
          value: "0",
        },
        {
          label: "Surcharge",
          value: "0",
        },
      ],
      dateForm: {
        date: "",
        time: "",
        startTime: "",
        endTime: "",
        phone: "",
        email: "",
        comment: "",
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    ...mapState("bar", ["sideBarStatus"]),
    ...mapGetters("apartment", ["addressStr"]),
    ...mapState("booking", ["bookdata"]),
    ...mapState("apartment", ["apartment", "room"]),
    ...mapState("user", ["userInfo"]),
    titleStr() {
      //判断标题的展示
      switch (this.bookdata.type) {
        case 0:
          return "Book Your Move In";
        case 1:
          return "Book Your Move Out";
        case 2:
          return "Select Date & Time";
        default:
          return "Book Your Move In";
      }
    },
    nextDisabled() {
      if (
        !this.dateForm.date ||
        !this.dateForm.time ||
        !regex.phone(this.dateForm.phone) ||
        !regex.email(this.dateForm.email)
      ) {
        return true;
      }
      return false;
    },
  },
  filters: {
    dateVal: function (val) {
      //判断是上午或者下午
      if (parseInt(val.substring(0, 2)) > 12) {
        return val + "pm";
      } else {
        return val + "am";
      }
    },
  },
  created() {
    if (this.sideBarStatus > -1) {
      this.barStatus = this.sideBarStatus.toString();
      this.setSideBarStatus();
    }
    if (this.bookdata.type !== 2) {
      this.queryAdvanceHour(
        this.room.apartmentId,
        this.room.apartmentUnitId,
        this.room.apartmentFloorId
      );
    }
    this.keyupSubmit();
  },
  mounted() {
    const { boundPhone, email } = this.userInfo;
    this.dateForm.phone = boundPhone;
    this.dateForm.email = email;
  },
  methods: {
    ...mapMutations("bar", ["setSideBarStatus"]),
    ...mapMutations("booking", ["setReduction"]),
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.dialogShow) {
            this.confirmPopup();
            return;
          }
          if (this.dateForm.time || this.dateForm.date) {
            this.facilityBooking();
            return;
          }
        }
      };
    },
    async queryAdvanceHour(apartmentId, apartmentUnitId, apartmentFloorId) {
      //请求当前apartment设定时间
      let that = this;
      let params = { apartmentUnitId, apartmentFloorId };
      await that.$axios
        .get(this.$api.getAdvanceHour(apartmentId), { params })
        .then((res) => {
          this.advanceHour = res.data.advanceHour;
        });
      this.getCanBookingDate();
    },
    // 日历时间
    getCanBookingDate() {
      const curTime = new Date();
      if (curTime.getHours() + this.advanceHour > 23) {
        const dateTime = curTime.setHours(
          curTime.getHours() + this.advanceHour
        );
        this.pickerOptions1 = {
          disabledDate(time) {
            return time.getTime() < dateTime;
          },
        };
      }
    },
    isFree() {
      let that = this;
      return this.$axios
        .get(this.$api.isFree(that.room.apartmentId), {
          params: {
            apartmentRoomId: that.room.id,
            apartmentFloorId: that.room.apartmentFloorId,
            apartmentUnitId: that.room.apartmentUnitId,
          },
        })
        .then((res) => {
          if (res.code === "1000") {
            return res.data;
          }
        });
    },
    //免费预定成功跳转
    confirmPopup() {
      this.setReduction();
      this.$router.push("/myBooking");
    },
    //提交订单
    facilityBooking() {
      let that = this;
      let params = {};
      if (that.bookdata.type === 2) {
        params.apartmentAreaId = parseInt(that.bookdata.facility.id); //公共设备id
        params.apartmentNumberName = that.bookdata.facility.name; //公共设备名称
        params.fullName = that.userInfo.name; //名字
        //params.phone = that.userInfo.boundPhone; //手机
        //params.email = that.userInfo.email; //邮箱
        params.address = that.addressStr; //地址
      }
      params.phone = that.dateForm.phone;
      params.email = that.dateForm.email;
      params.day = that.dateForm.date; //日期
      params.startTime = that.dateForm.startTime; //开始时间
      params.endTime = that.dateForm.endTime; //结束时间
      params.apartmentFloorId = that.room.apartmentFloorId;
      params.apartmentNumberId = parseInt(that.room.id); //房间号
      params.type = this.bookdata.type; //类型
      params.comment = that.dateForm.comment;

      this.$axios.post(this.$api.addOrder, params).then((res) => {
        if (res.code === "1000") {
          if (this.bookdata.type === 2) {
            that.successTittle = "Book successful";
            that.contentTxt =
              "Congratulation! You've successfully book the " +
              that.bookdata.facility.name +
              " on " +
              that.dateForm.time +
              " " +
              that.dateForm.date;
            that.dialogShow = true;
          } else {
            if (this.total > 0) {
              this.$router.push({
                path: "/pay",
                query: {
                  orderId: res.data.id,
                  feeType: this.total === 0 ? 0 : 1, //免费 or 付费
                  optionType: this.optionType,
                },
              });
            } else {
              that.successTittle = "Book successful";
              that.contentTxt = `Thank you! You have successfully booked ${this.getServiceName(
                this.bookdata.type
              )}.`;
              that.dialogShow = true;
            }
          }
        }
      });
    },
    //获取选择日期后的可选时间
    datePicker(date) {
      let that = this;
      if (!date) {
        return;
      }
      that.$set(that.dateForm, "date", date);
      if (this.bookdata.type === 2) {
        //免费
        that.$axios
          .get(this.$api.selTimes, {
            params: {
              apartmentAreaId: that.bookdata.facility.id, //公共设备id
              date: date, //选择日期
            },
          })
          .then((res) => {
            if (res.code === "1000") {
              that.timeIndex = "";
              that.$set(that.dateForm, "time", "");
              that.$set(that.dateForm, "startTime", "");
              that.$set(that.dateForm, "endTime", "");
              for (let i = 0; i < res.data.list.length; i++) {
                res.data.list[i].isCanAppointment = true;
              }
              that.timeData = res.data.list;
            }
          });
      } else if (this.bookdata.type === 0 || this.bookdata.type === 1) {
        //搬家
        const url = `${that.$api.getMoveFees(
          that.room.apartmentId
        )}?apartmentUnitId=${that.room.apartmentUnitId}&apartmentFloorId=${
          that.room.apartmentFloorId
        }&date=${date}`;
        that.$axios.get(url).then((res) => {
          if (res.code === "1000") {
            that.timeIndex = "";
            that.$set(that.dateForm, "time", "");
            that.$set(that.dateForm, "startTime", "");
            that.$set(that.dateForm, "endTime", "");
            that.timeData = res.data.list;
          }
        });
      }
    },
    resetTime() {
      //重置时间
      this.timeIndex = "";
      this.dateForm.time = "";
    },
    timeSlotCancel() {
      this.selectTimeVisible = false;
      this.resetTime();
    },
    async selectTime(v) {
      if (!this.timeData[v].isCanAppointment) {
        return;
      }
      //选择时间1
      this.timeIndex = v;
      this.dateForm.time =
        dateVal(this.timeData[v].startTime) +
        "-" +
        dateVal(this.timeData[v].endTime);
      this.dateForm.startTime = this.timeData[v].startTime;
      this.dateForm.endTime = this.timeData[v].endTime;
      if (this.bookdata.type === 1 || this.bookdata.type === 0) {
        const res = await this.isFree();
        if (!res.isFree) {
          this.moneyList = [
            {
              label: "Subtotal（GST Exclude）",
              value: this.timeData[v].price.toFixed(2).toString(),
            },
            {
              label: "GST",
              value: this.timeData[v].taxes.toFixed(2).toString(),
            },
            {
              label: "Surcharge",
              value: this.timeData[v].creditCardSurcharge.toFixed(2).toString(),
            },
          ];
          console.log(
            this.timeData[v].taxes.toFixed(2) +
              this.timeData[v].price.toFixed(2) +
              this.timeData[v].creditCardSurcharge.toFixed(2)
          );
          this.total = (
            parseFloat(this.timeData[v].taxes.toFixed(2)) +
            parseFloat(this.timeData[v].price.toFixed(2)) +
            parseFloat(this.timeData[v].creditCardSurcharge.toFixed(2))
          )
            .toFixed(2)
            .toString();
        }
      }
    },
    claimTime() {
      //关闭选择时间
      this.selectTimeVisible = false;
    },
    backClick() {
      //返回
      this.$router.back();
    },
    //服务类型
    getServiceName(bookingType) {
      switch (bookingType) {
        case 0:
          return "Move in service";
        case 1:
          return "Move out service";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
